:root {
  --primary-color-gradient: linear-gradient(87deg,
      #5f42e2 11.49%,
      #9b42c0 131%);
  --secondary-color-gradient: linear-gradient(87deg,
      #9b42c0 11.49%,
      #5f42e2 131%);
  --Dark-Gradient: linear-gradient(90deg, #8f78f4 0%, #8842ca 91.84%);
  --text-color-white: #fdfdfd;
}

body {
  margin: 0;
  font-family: "Graphik", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #050606 !important;
  overflow-x: hidden;
}

/* Modal Styling */
.modale {
  position: fixed;
  margin-top: 100px;
  max-height: 450px;
  scroll-behavior: smooth;
  overflow-x: hidden;
  padding: 20px;
  top: 20%;
  left: 10%;
  width: 80%;
  background-color: white;
  box-shadow: 21px 24px 5px -10px rgba(255, 255, 255, 0.21);
  color: #000;
  transition: all 0.9s ease-in;
  z-index: 9999;
}


.modale::-webkit-scrollbar {
  width: 10px;
}

.modale::-webkit-scrollbar-track {
  background: whitesmoke;
}

.modale::-webkit-scrollbar-thumb {
  background: linear-gradient(#642bff, #ff22e6);
  height: 20px;
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(20, 20, 20, 0.5);
  z-index: 5;
}

a {
  text-decoration: none !important;
}

* {
  font-family: "Graphik", sans-serif !important;
}

.Genti-wrapper {
  background: #050606;
  /* height: 100vh; */
  overflow-x: hidden;
}

.genti-container-fluid {
  width: 88%;
  margin: auto;
}

.header-wrapper {
  padding: 22px 0 36px 0;
  background: linear-gradient(180deg,
      rgba(4, 4, 4, 0.78) 10.09%,
      rgba(0, 0, 0, 0) 109.21%);
  z-index: 999;
}

.logo-container img {
  width: 136px;
  height: 78.608px;
  object-fit: cover;
}

.nav-item {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  padding-right: 25px;
}

.genti-gradient_btn {
  color: var(--text-color-white) !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500 !important;
  padding: 16px 30px !important;
  border-radius: 50px !important;
  background: var(--primary-color-gradient);
  transition: all 0.5s ease-in-out;
}

.genti-gradient_btn:hover {
  background: var(--secondary-color-gradient);
}

.hero-img__wrapper img {
  object-fit: cover;
  width: 100%;
}

.hero-img__wrapper {
  position: relative;
  width: 100%;
  display: inline-block;
}

.gradient {
  position: absolute;
  top: 0;
  /* left: 0; */
  width: 100%;
  height: 100%;
  /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
   */
  background: linear-gradient(180deg,
      rgba(18, 18, 18, 0) 30.25%,
      rgba(5, 6, 6, 0.98) 95.45%);
  z-index: 1;
}

/* .hero-img__wrapper {
  background: linear-gradient(180deg, rgba(18, 18, 18, 0.00) 0.25%, rgba(5, 6, 6, 0.94) 92.45%), url('./Assets/Img/hero.png');
  background-repeat: no-repeat;
  background-size: cover;
} */

.hero-wrapper,
.faq-wrapper,
.coins-header_wrapper {
  background: linear-gradient(180deg,
      rgba(18, 18, 18, 0.44) 0.25%,
      #050606 92.45%),
    url("./Assets/Img/hero_bg.png");
  height: 100vh;
  width: 100%;
  padding-top: 140px;
  background-repeat: no-repeat !important;
  background-size: cover;
}


.faq-wrapper {
  height: 48vh;

}

.hero-text_wrapper {
  /* width: 50% !important; */
}

.hero-main_text {
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 119.5%;
  letter-spacing: -1.2px;
  background: var(--Dark-Gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
  position: relative;
  -webkit-filter: drop-shadow(2px 2px #0a0e27);
  filter: drop-shadow(2px 2px #0a0e27);
  /* text-shadow: ; */
  /* text-shadow: -4px 3px 0 #3a50d9, -14px 7px 0 #0a0e27; */
}

.hero-sub_text {
  color: #fff;
  font-family: Graphik;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 155.5%;
  margin-bottom: 46px;
}

.hero-cta_container {
  background: url("./Assets/Img/ctaBtn.svg");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  /* width: 59px; */
  height: 59px;
  flex-shrink: 0;
}

.hero-img__wrapper {
  min-height: 530px !important;
}

.section-main_text {
  background: var(--Dark-Gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
}

.section-sub_text,
.guide_text {
  color: #fff;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 155.5%;
  letter-spacing: 0.5px;
  margin: 33px 0px;
}

.section-btn {
  padding: 18px 40px !important;
  font-size: 20px !important;
}

.section-text_wrapper {
  width: 70%;
}

.section-container {
  border-bottom: 5px solid #141414;
  /* height: 80vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
}

.originals-section {
  background: url("./Assets/Img/originals.svg"),
    url("./Assets/Img/originalsbg.svg");
  background-position: center 70%, 0% 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.language-section {
  background: url("./Assets/Img/languagebg.svg");
  background-position: center 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.section-img_wrapper img {
  width: 100%;
  object-fit: cover;
}

.Offline-section {
  background: url("./Assets/Img/offline_bg1.svg"),
    url("./Assets/Img/offline_bg2.svg");
  background-position: center 40%, left -80px;
  background-size: contain;
  background-repeat: no-repeat;
}

.Offline-section.section-container {
  border-bottom: 1px solid #141414;
}

footer {
  padding: 60px 0;
}

.footer-logo__wrapper {
  margin-bottom: 16.39px;
}

.footer-text {
  color: #ababab;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 155.5%;
  letter-spacing: 0.32px;
  margin-bottom: 33.58px;
}

.download-btn {
  border-radius: 39.848px !important;
  border: solid 1px #8e8e8e !important;
  padding: 9.563px 34.269px !important;
}

.download-text,
.download-store {
  color: #ededed;
  font-style: normal;
  line-height: 120%;
}

.download-text {
  font-size: 9.563px;
  font-weight: 400;
}

.download-store {
  font-size: 15.939px;
  font-weight: 500;
}

.footer-section.section-container {
  border: none;
}

.footer-header_text {
  color: #d6ccff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 155.5%;
  letter-spacing: 0.36px;
}

.footer-link__wrapper {
  margin-bottom: 20px;
}

.footer-link__wrapper,
.footer-link__wrapper li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-link__wrapper li,
.footer-link__wrapper li a,
.footer-link__wrapper .footer-link,
.footer-link__wrapper a {
  color: #ababab;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 155.5%;
  letter-spacing: 0.32px;
  margin-bottom: 12px !important;
  cursor: pointer;
}

.footer-link__wrapper li:hover,
.footer-link__wrapper a:hover,
.footer-link:hover {
  color: #fff !important;
}

.footer-copyright__wrapper {
  border-top: 1px solid #2b2b2b;
  padding-top: 55px;
}

.footer-sub-text {
  color: #959595;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 155.5%;
  margin-bottom: 0;
}

a {
  text-decoration: none;
  color: unset;
}



.partners_logo {
  object-fit: contain;
  width: 100px;
  height: 100px;

}

.partners_logo.cchub {
  width: 230px
}


.logo_separator {
  border-style: solid;
  border-width: 0px 2px 0px 0px;
  border-color: #ababab;
  height: 5em;
}




.about-us a {
  background: var(--Dark-Gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline !important;
  border-bottom: 1px solid #5f42e2;
}

.faq-container {
  cursor: pointer;
  background: #242525;

}


.faq-question {
  color: #fff;
  font-weight: 600;
}

.faq-icon_wrapper {
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.faq-icon_wrapper.inactive {
  border: solid 1px white;
  background-color: #242525 !important;
}

.faq-link {
  text-decoration: none;
  color: #5f42e2 !important;
}

.faqs-wrapper {
  width: 50%;
  margin: auto;
}


.coins-header_wrapper {
  background: linear-gradient(180deg,
      rgba(18, 18, 18, 0.772) 0.25%,
      #050606d6 92.45%), url("./Assets/Img/coin_bg.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  /* padding-top: 110px; */
}

.coin_icon {
  height: 65px;
  width: 65px;
}

.icon {
  object-fit: cover;
  height: 35px;
  width: 35px;
}

.steps_icon {
  height: auto;
  width: 90%;
  object-fit: contain;

}


.step-number {

  font-size: 208px;
  -webkit-text-stroke: 5px #8942CA;
  /* Outline thickness and color */
  color: transparent;
  /* Make the fill transparent */
  font-weight: 600;
  box-sizing: border-box !important;
  line-height: 0.4;
}

.genti-coins-wrapper {
  background: #121212c6;
}

.coin-description {

  font-size: 168x !important;
  font-weight: 600 !important;
  line-height: 30px !important;

}

.coins_page {
  width: 100vw !important;
  overflow-x: hidden;
}

@media (min-width: 1500px) {
  .section-text_wrapper {
    width: 50%;
  }

  .hero-text_wrapper {
    width: 75% !important;
  }
}

@media (min-height: 800px) and (min-width: 969px) {
  .hero-wrapper {
    height: unset;
    /* min-height: 756px; */
  }

  .section-container {
    /* height: 672px; */
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .footer-header_text {
    font-size: 0.85em;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .section-text_wrapper {
    width: 80%;
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .section-text_wrapper {
    width: 70%;
  }

  .language-section .section-text_wrapper {
    width: 80%;
  }
}

@media (min-width: 1500px) and (max-width: 2000px) {
  .section-text_wrapper {
    width: 90%;
  }
}

@media (min-width: 2000px) {
  .section-text_wrapper {
    width: 95%;
  }
}

@media (min-width: 576px) and (max-width: 769px) {
  .hero-main_text {
    text-align: left;
  }

  .hero-sub_text {
    text-align: left;
  }

  .section-main_text {
    font-size: 32px;
    margin-bottom: 18px;
    text-align: left;
  }

  .section-sub_text {
    text-align: left;
  }
}

/* tablet */
@media (min-width: 769px) and (max-width: 1024px) {
  .hero-main_text {
    font-size: 48px;
  }

  .hero-sub_text {
    font-size: 18px;
  }

  .hero-img__wrapper {
    min-height: 490px !important;
  }

  .hero-img__wrapper img {
    object-fit: unset;
    width: 100%;
    height: 100%;
  }

  .hero-wrapper {
    height: unset;
    min-height: 630px !important;
  }

  .section-main_text {
    font-size: 48px;
  }

  .section-sub_text {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 155.5%;
    letter-spacing: 0.5px;
    margin: 33px 0px;
  }

  .section-text_wrapper {
    width: 80%;
  }

  .genti-container-fluid {
    width: 91%;
  }

  .download-btn {
    padding: 9.563px 25.269px !important;
  }

  .download-text {
    text-align: left;
  }
}


@media (max-width: 769px) {
  .logo-container img {
    width: 70px;
    height: 40px;
    flex-shrink: 0;
  }

  .hero-main_text {
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    text-shadow: 4px 4 2px rgba(0, 0, 0, 0.76);
    line-height: 119.5%;
    letter-spacing: -0.64px;
  }

  .hero-img__wrapper {
    min-height: unset !important;
  }

  .hero-sub_text {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 46px;
    text-align: center;
  }

  .hero-cta_container {
    background: url("./Assets/Img/cta_btn_mobile.svg");
    background-position: center;
    background-repeat: no-repeat;
  }

  .hero-wrapper {
    background: linear-gradient(180deg,
        rgba(18, 18, 18, 0.44) 0.25%,
        #050606 92.45%),
      url("./Assets/Img/herobg_mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
  }

  .originals-section {
    background: url("./Assets/Img/original_mobilebg.svg");
    background-position: center 70%;
  }

  .section-text_wrapper {
    width: 100% !important;
  }

  .section-main_text {
    font-size: 32px;
    margin-bottom: 18px;
    text-align: center;
  }

  .section-sub_text {
    font-size: 14px;
    text-align: center;
    margin: 18px 0px 48px 0px;
    line-height: 155.5%;
    /* 21.77px */
    letter-spacing: 0.28px;
  }

  .section-btn__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .language-section {
    background: url("./Assets/Img/languqge_mobilebg.svg"),
      url("./Assets/Img/language_mobilebg2.svg");
    background-repeat: no-repeat;
    background-position: center, left -240px;
  }

  .offline-text {
    font-size: 30px;
  }

  .footer-logo__wrapper img {
    width: 99px;
    height: 54px;
    object-fit: cover;
  }

  .footer-text {
    color: #ababab;
    font-size: 14px;
  }

  .Offline-section {
    background: url("./Assets/Img/offline_mobilebg.svg"),
      url("./Assets/Img/offline_mobilebg2.svg");
    background-position: center -20px, left -200px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .download-btn {
    border-radius: 39.848px !important;
    padding: 9.563px 18.269px !important;
  }

  .download-text {
    font-size: 8px;
    font-weight: 400;
  }

  .download-store {
    font-size: 13px;
  }

  .download-btn img {
    width: 20.5px;
    height: 24.344px;
  }

  .footer-copyright__wrapper {
    padding: 18px 0 55px 0;
  }

  .section-img_wrapper {
    margin-bottom: 30px;
  }

  .mobile-header_btn {
    font-size: 14px !important;
    padding: 10px 14px !important;
  }

  .section-btn {
    padding: 16px 32px !important;
    font-size: 16px !important;
  }

  .footer-header_text {
    font-size: 16px;
    font-weight: 500;
    line-height: 155.5%;
    letter-spacing: 0.32px;
  }

  .step-number {
    -webkit-text-stroke: 1px #8942CA;

    font-size: 40.42px;
    font-weight: 600;
    line-height: 48.51px;
    /* text-align: center; */


  }

  .faqs-wrapper {
    width: 90%;
  }

  .faq-wrapper {
    height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .partners_logo {
    width: 75px;

  }

  .partners_logo.cchub {
    width: 230px
  }



  .guide_text {
    /* font-size: 20px;
    text-align: center;
    margin: 17px 0 33px 0 !important; */
    font-size: 14px;
    /* text-align: center; */
    margin: 0 !important;
    font-weight: 400;
  }

  .arrow-icon {
    width: 30px;
    height: 7px;
  }

  .icon {
    height: 23px;
    width: 23px;
  }

  .coin-description {

    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 18px !important;

  }
}

@media (max-width: 700px) {
  .modale {
    max-height: 550px;
  }
}